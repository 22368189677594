import React, { useEffect } from "react";
import { Skeleton, Grid } from "@mui/material";
import { ForecastReviewGrid } from "../../Components/ForecastReviewGrid";
import ProductMultiSelection from "../../Components/ProductMultiSelection";
import { useForecastReviewContext } from "../../Contexts/forecastReviewContext";
import { useDistributorsContext } from "../../Contexts/distributorsContext";
import { CustomerType } from "../../Enums/CustomerType";
import { useProductsContext } from "../../Contexts/productsContext";
import DistributorMultiselection from "../../Components/DistributorMultiSelection";

export const ForecastReport = ({
  editableWeeksStartIndex,
  editableWeeksEndIndex,
  startWeek, 
  endWeek
}) => {
  const { selectedDistributors } = useDistributorsContext();
  const { selectedProducts, setSelectedProducts } = useProductsContext();
  const { 
    isReady: areForecastReviewItemsReady, 
    forecastReviewItems, 
    getFormattedOriginalForecastReviewItems, 
    setForecastReviewItems, 
    getForecastReport 
  } = useForecastReviewContext();

  useEffect(() => {
    if (areForecastReviewItemsReady) {
      getForecastReport();
    }
  }, [selectedDistributors, areForecastReviewItemsReady]);

  useEffect(() => {
    if (forecastReviewItems?.length === 0) {
      const selectedSKUs = selectedProducts?.map(selectedProduct => selectedProduct.sku);
      setForecastReviewItems(getFormattedOriginalForecastReviewItems().filter(forecastReviewItem => selectedSKUs.length > 0 ? selectedSKUs.includes(forecastReviewItem.product.sku) : true));
    }
  }, [selectedProducts, setSelectedProducts]);

  return (
    <React.Fragment>
      <Grid container columnSpacing={2} padding={2} justifyContent="end" alignItems="center">
        <Grid item xs={4}>
          <DistributorMultiselection
            customerType={CustomerType.VMI}
            filterByCanOrderBeer={true}
            onClose={() => getForecastReport()}
          />
        </Grid>
        <Grid item xs={4}>
          <ProductMultiSelection />
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>

      {!selectedDistributors || !areForecastReviewItemsReady ? (
        selectedDistributors && !areForecastReviewItemsReady ? (
          <Skeleton variant="rectangular" height={400} />
        ) : (
          <center style={{ padding: 25 }}>
            <h4>Please select a distributor</h4>
          </center>
        )
      ) : forecastReviewItems.length === 0 ? (
        <center style={{ padding: 25 }}>
          <h4>There is no historical data for the selected filter</h4>
          <h5>
            If you believe this is an error, please contact your business
            partner or the helpdesk at
            <a href="mailto: help@bostonbeer.com"> help@bostonbeer.com</a>
          </h5>
        </center>
      ) : (
        <ForecastReviewGrid editableWeeksStartIndex={editableWeeksStartIndex} editableWeeksEndIndex={editableWeeksEndIndex} startWeek={startWeek} endWeek={endWeek} isHistorical={true}/>
      )}
    </React.Fragment >
  );
};