import React, { useContext, useEffect, useState } from "react";
import { useDistributorsContext } from "./distributorsContext";
import { isInt } from "../Utilities/utilities.js";
import { useProductsContext } from "./productsContext.jsx";
import PropTypes from 'prop-types';

const url = `/forecastreview`;

export const ForecastReviewContext = React.createContext(null);

export function ForecastReviewContextProvider(props) {
    const { selectedDistributors } = useDistributorsContext();
    const { selectedProducts } = useProductsContext();
    const [originalForecastReviewItems, setOriginalForecastReviewItems] = useState([]);
    const [forecastReviewItems, setForecastReviewItems] = useState([]);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [error, setError] = useState("");

    const get = async () => {
        if (selectedDistributors && selectedDistributors.length > 0) {
            const controller = new AbortController();
            const signal = controller.signal;

            let distributors = "";
            selectedDistributors.forEach(distributor => distributors += `distributorIds=${distributor.distributorId}&`);

            try {
                const response = await fetch(`${url}/multiple?${distributors}`, { signal });

                const data = await response.json();
                if (response.ok) {
                    const selectedSKUs = selectedProducts?.map(selectedProduct => selectedProduct.sku);
                    setOriginalForecastReviewItems(data.forecastReviewItems);
                    setForecastReviewItems(data.forecastReviewItems?.map((forecast, index) => {
                        let temp = structuredClone(forecast);
                        return { ...temp, id: index };
                    }).filter(forecastReviewItem => selectedSKUs.length > 0 ? selectedSKUs.includes(forecastReviewItem.product.sku) : true));
                    setIsReadOnly(data.isReadOnly)
                    setIsReady(!error && data.forecastReviewItems?.length >= 0);
                } else {
                    setError(data);
                }
            } catch (error) {
                console.error('Error:', error);
                setError(error);
            }
        } else {
            setOriginalForecastReviewItems([]);
            setIsReady(false);
            setError("");
        }
    };

    const getForecastReport = async () => {
        if (selectedDistributors && selectedDistributors.length > 0) {
            const controller = new AbortController();
            const signal = controller.signal;

            let distributors = "";
            selectedDistributors.forEach(distributor => distributors += `distributorIds=${distributor.distributorId}&`);

            try {
                const response = await fetch(`${url}/report?${distributors}`, { signal });

                const data = await response.json();
                if (response.ok) {
                    const selectedSKUs = selectedProducts?.map(selectedProduct => selectedProduct.sku);
                    setOriginalForecastReviewItems(data.forecastReviewItems);
                    setForecastReviewItems(data.forecastReviewItems?.map((forecast, index) => {
                        let temp = structuredClone(forecast);
                        return { ...temp, id: index };
                    }).filter(forecastReviewItem => selectedSKUs.length > 0 ? selectedSKUs.includes(forecastReviewItem.product.sku) : true));
                    setIsReadOnly(data.isReadOnly)
                    setIsReady(!error && data.forecastReviewItems?.length >= 0);
                } else {
                    setError(data);
                }
            } catch (error) {
                console.error('Error:', error);
                setError(error);
            }
        } else {
            setOriginalForecastReviewItems([]);
            setIsReady(false);
            setError("");
        }
    };

    useEffect(() => {
        if (isReady) {
            const selectedSKUs = selectedProducts?.map(selectedProduct => selectedProduct.sku);
            setForecastReviewItems(mapForecastItems(originalForecastReviewItems).filter(forecastReviewItem => selectedSKUs.length > 0 ? selectedSKUs.includes(forecastReviewItem.product.sku) : true));
        }
    }, [isReady]);

    const getFormattedOriginalForecastReviewItems = (items) => {
        if(items) {
            return items.map((forecast, index) => {
                let temp = structuredClone(forecast);
                return { ...temp, id: index };
            })
        } else {
            return originalForecastReviewItems.map((forecast, index) => {
                let temp = structuredClone(forecast);
                return { ...temp, id: index };
            });
        }
    }

    const mapForecastItems = (forecastItems) => forecastItems.map((forecast, index) => {
        let temp = structuredClone(forecast);
        return { ...temp, id: index };
    });

    async function SaveForecastAdjustmentChanges() {
        let forecastAdjustmentsToUpdateOrCreate = [];
        let forecastAdjustmentsToDelete = [];
        let updatedForecastReviewItems = originalForecastReviewItems;

        forecastReviewItems.forEach((forecastReviewItem, index) => {
            forecastReviewItem.forecastAmounts?.forEach((forecastAmount, forecastAmountIndex) => {
                if (forecastAmount.adjustedAmount !== originalForecastReviewItems[index].forecastAmounts[forecastAmountIndex].adjustedAmount) {
                    if (isInt(forecastAmount.adjustedAmount)) {
                        forecastAdjustmentsToUpdateOrCreate.push({
                            forecastAdjustmentId: forecastAmount.forecastAdjustmentId,
                            amount: forecastAmount.adjustedAmount,
                            sku: forecastReviewItem.product.sku,
                            distributorId: forecastReviewItem.distributorId,
                            weekId: forecastAmount.weekId,
                        });
                        updatedForecastReviewItems[index].forecastAmounts[forecastAmountIndex].adjustedAmount = forecastAmount.adjustedAmount;
                    } else {
                        forecastAdjustmentsToDelete.push({...forecastAmount, distributorId: forecastReviewItem.distributorId});
                        updatedForecastReviewItems[index].forecastAmounts[forecastAmountIndex].adjustedAmount = null;
                    }
                }
            });
        });

        if (forecastAdjustmentsToUpdateOrCreate.length > 0) {
            await PutForecastAdjustments(forecastAdjustmentsToUpdateOrCreate);
        }
        if (forecastAdjustmentsToDelete.length > 0) {
            await DeleteForecastAdjustments(forecastAdjustmentsToDelete);
        }

        //update originalForecastReviewItems
        setOriginalForecastReviewItems(updatedForecastReviewItems);

        if (forecastAdjustmentsToUpdateOrCreate.length > 0 || forecastAdjustmentsToDelete.length > 0) {
            await get();
        }
    }

    async function PutForecastAdjustments(forecastAdjustments) {
        const controller = new AbortController();
        const signal = controller.signal;

        for (const selectedDistributor of selectedDistributors) {
            const forecastDtos = forecastAdjustments.filter(forecast => forecast.distributorId === selectedDistributor.distributorId).map(adjustment => {
                return { weekId: adjustment.weekId, sku: adjustment.sku, amount: adjustment.amount.toString() };
            });

            if (forecastDtos?.length > 0) {
                const requestOptions = {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(forecastDtos),
                    signal: signal,
                };

                try {
                    const response = await fetch(`/api/Distributor/${selectedDistributor.sapId}/forecasts`, requestOptions);

                    if (!response.ok) {
                        const data = await response.json();
                        setError(data)
                    }
                } catch (error) {
                    console.error('Error:', error);
                    setError(error);
                }
            }
        }
    }

    async function DeleteForecastAdjustments(forecastAdjustments) {
        const controller = new AbortController();
        const signal = controller.signal;
        if (selectedDistributors) {
            for (const selectedDistributor of selectedDistributors) {
                const requestOptions = {
                    method: "DELETE",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(forecastAdjustments.filter(forecastAdjustment => forecastAdjustment?.distributorId === selectedDistributor.distributorId).map(forecastAdjustment => forecastAdjustment.forecastAdjustmentId)),
                    signal: signal,
                };
                await fetch(`${url}/`, requestOptions)
                    .then(async (response) => {
                        const isJson = response.headers
                            .get("content-type")
                            ?.includes("application/json");
                        const data = isJson && (await response.json());

                        // check for error response
                        if (!response.ok) {
                            // get error message from body or default to response status
                            const error = (data && data.message) || response.status;
                            return Promise.reject(error);
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        setError(error);
                    });
            }
        }
    }

    return (<ForecastReviewContext.Provider
        value={{
            setForecastReviewItems,
            forecastReviewItems,
            SaveForecastAdjustmentChanges,
            isReady,
            isReadOnly,
            error,
            getFormattedOriginalForecastReviewItems,
            get,
            getForecastReport,
        }}
    >
        {props.children}
    </ForecastReviewContext.Provider>);
}

ForecastReviewContextProvider.propTypes = {
    children: PropTypes.node
}

export function useForecastReviewContext() {
    const context = useContext(ForecastReviewContext);

    if (!context) throw new Error("useForecastReviewContext must be used within a ForecastReviewContextProvider. Wrap a parent component in <ForecastReviewContextProvider> to fix this error.");
    return context;
}